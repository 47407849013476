import React from 'react'
import Layout from '../components/Layout'
import RightColumn from "../components/RightColumn";
import { TermsOfUse } from '@tyson-foods/react-component-library'
import { Container } from "reactstrap";
import { Link } from "gatsby";


export default class Terms extends React.Component {
    render() {

        return (
            <Layout sidebarImageUrl="/images/photo22.jpg" sidebarImageAlt=""  pageTitle="Terms of Use">
                <div id="mainContent">
                    <div id="panel">
                        <div className="pt-4"/>
                            <Container fluid={true} >
                                <TermsOfUse
                                    website="https://www.wbalabs.com"
                                    className="px-3 py-4 py-lg-5 px-md-4 px-lg-5"
                                    siteName = "Tyson Pet Products Inc. website"
                                    privacyPolicyLink={<Link to="/privacy-policy/">PRIVACY POLICY</Link>}
                                />
                            </Container>
                    </div>
                </div>
                <RightColumn />
            </Layout>
        )
    }
}
