import React from "react";
import $ from "jquery";

class RightColumn extends React.Component {
  componentDidMount() {
    $(document).ready(function() {
      $(".button_blue").click(function() {
        $(".button_blue ul").slideDown(300);
        $(".button_blue").css(
          "background",
          "url('/images/WBA-Button-Blue-Down.jpg') top right no-repeat"
        );

        var timer = setTimeout(function() {
          if ($(".button_blue ul").is(":visible")) {
            $(".button_blue ul").slideUp(600);
            $(".button_blue").css(
              "background",
              "url('/images/WBA-Button-Blue.jpg') top right no-repeat"
            );
          }
        }, 5000);

        $(".button_blue ul").mouseenter(function() {
          clearTimeout(timer);
          $(this).stop();
          $(this).css("height", "auto");
          $(".button_blue").css(
            "background",
            "url('/images/WBA-Button-Blue-Down.jpg') top right no-repeat"
          );
        });
      });

      $(".button_blue ul").mouseleave(function() {
        var timer = setTimeout(function() {
          if ($(".button_blue ul").is(":visible")) {
            $(".button_blue ul").slideUp(800);
            $(".button_blue").css(
              "background",
              "url('/images/WBA-Button-Blue.jpg') top right no-repeat"
            );
          }
        }, 5000);

        $(".button_blue ul").mouseenter(function() {
          clearTimeout(timer);
          $(this).stop();
          $(this).css("height", "auto");
          $(".button_blue").css(
            "background",
            "url('/images/WBA-Button-Blue-Down.jpg') top right no-repeat"
          );
        });
      });
    });
  }
  render() {
    return (
      <div id="sidebar2">
        <div id="green_headers">
          <div className="header-three">My Data/Resources</div>
          <div className="button_blue">
            <a>Submission Forms</a>
            <ul>
              <li>
                <a href="/documents/wba-submission-form.xlsx">
                  Analytical Form
                </a>
              </li>
              <li>
                <a href="/documents/wba-submission-form.xlsx">
                  Nutritional Labeling Form
                </a>
              </li>
            </ul>
          </div>
          <div className="button_gray">
            <p>
              <a href="/documents/wba-arf2.pdf" target="_blank" rel="noopener noreferrer">
                New Customers
              </a>
            </p>
          </div>
          <div className="button_gold">
            <p>
              <a href="/documents/catalog-of-services.pdf" target="_blank"  rel="noopener noreferrer">
                Testing Catalog (PDF)
              </a>
            </p>
          </div>
        </div>

        <div id="gold_headers">
          <div className="header-three">ISO Accreditations</div>
          <p>
            <a
              href="http://www.a2la.org/dirsearchnew/newsearch.cfm"
              target="_blank"  rel="noopener noreferrer"
            >
              <img
                style={{
                  border: "0px solid currentcolor",
                  width: "95px",
                  height: "92px"
                }}
                alt="Accredidations All locations"
                src="/images/accredidations_alllocations.jpg"
              />
            </a>
            <a
              //href="/documents/tuv_certificate_2011.pdf"
              title="ISO 9001 Certificate"
              //target="_blank"  rel="noopener noreferrer"
            >
              <img
                style={{
                  border: "0px solid currentcolor",
                  width: "76px",
                  height: "89px"
                }}
                alt="Accreditation Springdale"
                src="/images/accredidations_springdale.png"
              />
            </a>
            <br />
            <a href="/quality-confidence">
              See all accreditations, approvals, and proficiency programs...
            </a>
          </p>
          <map
            name="rade_img_map_1311344388431"
            id="rade_img_map_1311344388431"
            submitname="rade_img_map_1311344388431"
          >
            <area
              href="http://www.a2la.org"
              title="A2LA"
              shape="rect"
              coords="0,0,100,96"
              alt="A2LA"
            />
            <area
              href="http://tuvamerica.com/newhome.cfm"
              title="TUV"
              shape="rect"
              coords="101,0,186,96"
              alt="TUV"
            />
          </map>
        </div>
      </div>
    );
  }
}

export default RightColumn;
